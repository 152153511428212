import { connect } from 'react-redux'
import { openLeaguesPropagationPopup, getLeaguesPropagations } from '../actions/index'
import MembersAlertComponent from '../components/members_alert_component'

const mapStateToProps = (state) => ({
  show: state.leaguesPropagation.isOpen,
  displayPropagationAlert: state.misc.modalChangeHandicapIndex,
  showInvalidEmailsMessage: state.membersData.showInvalidEmailsMessage,
  isAusCustomer: state.misc.isAusCustomer,
})

const mapDispatchToProps = (dispatch) => ({
  openLeaguesPropagationPopup: () => dispatch(openLeaguesPropagationPopup()),
  getLeaguesPropagations: () => dispatch(getLeaguesPropagations()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembersAlertComponent)
