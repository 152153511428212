import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'
import FieldsListComponent from './fields_list_component'
import {Form, Button} from 'react-bootstrap'
import GlgLoadingSpinner from '../../../shared/components/glg_loading_spinner'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { Scrollbar} from 'react-scrollbars-custom'

export class FieldsComponent extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.swapFields = this.swapFields.bind(this)
    this.deleteRoster = this.deleteRoster.bind(this)
  }
  
  handleSubmit(e){
    e.preventDefault()
    const data = {
      league: {
        visible_columns: this.props.leftFields.join(';;;'),
        right_columns: this.props.rightFields.join(';;;'),
      },
    }
    this.props.setMemberColumns(data)
  }
  
  swapFields(result){
    this.props.swapFields(result.source.index, result.destination.index)
  }
  
  deleteRoster(){
    this.props.openDeleteRosterConfirmationPopup()
    this.props.getDeleteRosterConfirmationAnswer()
  }

  render() {    
    return <Col xs={12} className={'leftsidebar_container no_padding_right no_padding_left left_sidebar_open_container'} id="fields_options_content"><Form onSubmit={this.handleSubmit}><div style={{overflow: 'hidden'}} className="btsp_ps_container_normal_scroll leftsidebar_paddings" id="choose_league_columns_scroll"><div className="league_form"><div className="title">{ !window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.fields_component.fields') }</div><div className="clear subtitle">{ !window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.fields_component.fields_info') }</div><DragDropContext onDragEnd={this.swapFields}><Droppable droppableId="droppable">{(provided, snapshot) => <Scrollbar style={{height: 'auto'}} renderer={props => {
                        const { elementRef, ...restProps } = props
                        return <span {...restProps} ref={elementRef} style={{...restProps.style, position: 'relative', display: 'flex', flexDirection: 'column', overflow: 'auto'}}/>
                      }} wrapperProps={{
                        renderer: props => {
                          const { elementRef, ...restProps } = props
                          return <div {...restProps} ref={elementRef} style={{...restProps.style, position: 'relative', display: 'flex', flexDirection: 'column'}} />
                        },
                      }} scrollerProps={{
                        renderer: props => {
                          const { elementRef, ...restProps } = props
                          return <div {...restProps} ref={elementRef} style={{...restProps.style, position: 'relative', display: 'flex', flexDirection: 'column'}} />
                        },
                      }} trackYProps={{
                        renderer: props => {
                          const { elementRef, ...restProps } = props
                          return <span {...restProps} ref={elementRef} style={{...restProps.style, height: 'calc(100% - 50px)'}} />
                        },
                      }} disableTracksWidthCompensation={true}><div className="members_fields sidebar_form_content"><input type="hidden" name="league[visible_columns]" /><input type="hidden" name="league[right_columns]" /><GlgLoadingSpinner show={this.props.isLoading} height={46} width={46}></GlgLoadingSpinner><FieldsListComponent fields={this.props.leftFields} checkedColumns={true} checkMemberField={this.props.checkMemberField} uncheckMemberField={this.props.uncheckMemberField} provided={provided} snapshot={snapshot} indexType={this.props.indexType} prettyHandicapMethod={this.props.prettyHandicapMethod} membershipPrograms={this.props.membershipPrograms} showHandleTooltip={this.props.showHandleTooltip} showLastNameTooltip={this.props.showLastNameTooltip} isAusCustomer={this.props.isAusCustomer}></FieldsListComponent><FieldsListComponent fields={this.props.rightFields} checkedColumns={false} checkMemberField={this.props.checkMemberField} uncheckMemberField={this.props.uncheckMemberField} indexType={ this.props.indexType } prettyHandicapMethod={ this.props.prettyHandicapMethod } membershipPrograms={ this.props.membershipPrograms } isAusCustomer={this.props.isAusCustomer}></FieldsListComponent>{ !this.props.isLoading && <a href="#" className={ 'btsp_red_button ' + (this.props.cannotDeleteRoster ? 'disabled' : '')} id="delete_entire_roster" onClick={this.deleteRoster} style={{marginTop: '20px'}}>                        { !window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.fields_component.delete_entire_roster') }</a>}</div></Scrollbar>}</Droppable></DragDropContext></div></div><fieldset className="submit arrange_right submit_buttons no-top-border"><a href="javascript:void(0)" className="btn button btsp_white_button close_sidebar" onClick={this.props.closeFieldsSidebar}>{ !window.I18n ? '' : window.I18n.t('buttons.cancel').charAt(0)
                .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.cancel').slice(1)) }</a><Button type="submit" className={`btsp_orange_button pull-right ${this.props.isMembersLoading ? 'disabled' : ''}`} disabled={this.props.isMembersLoading}>{this.props.isMembersLoading ? (!window.I18n ? '' : window.I18n.t('buttons.saving')) : (!window.I18n ? '' : window.I18n.t('members_page.components.sidebar_content.fields_component.save_fields'))}</Button></fieldset></Form></Col>
  }
}

FieldsComponent.propTypes = {
  leftFields: PropTypes.array.isRequired,
  rightFields: PropTypes.array.isRequired,
  setMemberColumns: PropTypes.func.isRequired,
  checkMemberField: PropTypes.func.isRequired,
  uncheckMemberField: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isMembersLoading: PropTypes.bool.isRequired,
  swapFields: PropTypes.func.isRequired,
  openDeleteRosterConfirmationPopup: PropTypes.func.isRequired,
  getDeleteRosterConfirmationAnswer: PropTypes.func.isRequired,
  closeFieldsSidebar: PropTypes.func.isRequired,
  multiGrid: PropTypes.any,
  indexType: PropTypes.string,
  prettyHandicapMethod: PropTypes.string,
  membershipPrograms: PropTypes.object.isRequired,
  cannotDeleteRoster: PropTypes.bool.isRequired,
  showHandleTooltip: PropTypes.bool,
  showLastNameTooltip: PropTypes.bool,
  elementRef: PropTypes.any,
  isAusCustomer: PropTypes.bool,
}

export default FieldsComponent
