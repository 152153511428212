const TEE = {
  value: 'tee',
  label: 'Tee',
}
const FAIRWAY = {
  value: 'fairway',
  label: 'Fairway',
}
const ROUGH = {
  value: 'rough',
  label: 'Rough',
}
const BF = {
  value: 'bunker fairway',
  label: 'Bunker Fairway',
}
const BG = {
  value: 'bunker greenside',
  label: 'Bunker Greenside',
}

const GREEN = {
  value: 'green',
  label: 'Green',
}
const PENALTY = {
  value: 'penalty',
  label: 'Penalty',
}
const PU = {
  value: 'pick-up',
  label: 'Pick-Up',
}

export const POSITIONS = {
  'tee': [ TEE, FAIRWAY, ROUGH, BF, BG, GREEN, PENALTY, PU ],
  'fairway': [ FAIRWAY, ROUGH, BF, BG, GREEN, PENALTY, PU ],
  'rough': [ FAIRWAY, ROUGH, BF, BG, GREEN, PENALTY, PU ],
  'bunker fairway': [ ROUGH, BF, BG, GREEN, PENALTY, FAIRWAY, PU ],
  'bunker greenside': [ FAIRWAY, ROUGH, BG, GREEN, PENALTY, PU ],
  'green': [ GREEN, PENALTY, PU ],
  'penalty': [ TEE, FAIRWAY, ROUGH, BF, BG, GREEN, PENALTY, PU ],
  'pick-up': [ ],
}

export const scoreClass = (holeScore, holePar, holeView) => {
  if ( holeScore === null || holeScore === 0) {
    return ''
  }

  if (holeScore === -2) {
    return holeView === 'colors' ? 'plus2-hole' : 'double_square'
  }
  const score = holeScore - holePar

  if (holeView === 'colors') {
    if (score === 0) {
      return ''
    } else if (score === -1) {
      return 'birdie-hole'
    } else if (score <= -2) {
      return 'eagle-hole'
    } else if (score === 1) {
      return 'plus1-hole'
    } else if (score >= 2) {
      return 'plus2-hole'
    }
  } else {
    if (score === 0) {
      return ''
    } else if (score === -1) {
      return 'simple_circle'
    } else if (score <= -2) {
      return 'double_circle'
    } else if (score === 1) {
      return 'simple_square'
    } else if (score >= 2) {
      return 'double_square'
    }
  }
}
