import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { GlgPopup, GlgCheckbox, GlgSelect } from 'SharedComponents'
import { POSITIONS } from '../helpers'

class HoleSummaryComponent extends Component{
  constructor(props) {
    super(props)
    this.state = {
      shots: [ ...this.props.shots ],
      completed: this.props.isCompleted,
      newPositions: {},
      addShot: false,
      newShot: {},
      enableSave: true, 
      disableAddShot: false,
    }
    this.handleRemove = this.handleRemove.bind(this)
    this.handleCompletedChange = this.handleCompletedChange.bind(this)
    this.toggleEdit = this.toggleEdit.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handlePositionChange = this.handlePositionChange.bind(this)
    this.handleAddShot = this.handleAddShot.bind(this)
    this.handelConfirmNewShot = this.handelConfirmNewShot.bind(this)
    this.handleCancelNewShot = this.handleCancelNewShot.bind(this)
    this.handleClearAllShots = this.handleClearAllShots.bind(this)
    this.onSave = this.onSave.bind(this)
  }

  handleRemove(shotIndex) {
    const currentShots = [ ...this.state.shots ]
    currentShots.splice(shotIndex, 1)
    const updatedShots = currentShots.map( (shot, index) => {
      return (index >= shotIndex) ? 
        {
          ...shot,
          shot_number: shot.shot_number - 1,
        } 
        : shot 
    })
    this.setState({shots: updatedShots})
  }

  handleCompletedChange(value) {
    this.setState({completed: value})
  }

  toggleEdit(shotIndex) {
    const currentShots = [ ...this.state.shots ]
    const updatedShots = currentShots.map( (shot, index) => {
      return (index === shotIndex) ?
        {
          ...shot,
          edit: !shot.edit,
        } 
        : shot
    })

    const positions = { 
      ...this.state.newPositions,
      [shotIndex]: this.state.shots[shotIndex].position,
    }
    const shotsInEditMode = updatedShots.some(shot => shot.edit === true)
    const enableSaveButton = !shotsInEditMode && !this.state.addShot
    this.setState({shots: updatedShots, newPositions: positions, enableSave: enableSaveButton})
  }

  handleEdit(shotIndex) {
    const currentShots = [ ...this.state.shots ]
    const updatedShots = currentShots.map( (shot, index) => {
      return (index === shotIndex) ?
        {
          ...shot,
          edit: !shot.edit,
          position: this.state.newPositions[shotIndex],
        } 
        : shot
    })
    const shotsInEditMode = updatedShots.some(shot => shot.edit === true)
    const enableSaveButton = !shotsInEditMode && !this.state.addShot
    this.setState({shots: updatedShots, enableSave: enableSaveButton})
  }

  handlePositionChange(shotIndex, position) {
    const size = this.state.shots.length
    if (shotIndex < size) {
      const positions = { 
        ...this.state.newPositions,
        [shotIndex]: position,
      }
      this.setState({newPositions: positions})
    } else {
      this.setState({
        newShot: {
          ...this.state.newShot,
          position: position,
        },
      })
    }
  }

  handleAddShot() {
    const shots = [ ...this.state.shots ]
    const index = shots.length
    const availablePositions = index === 0 ? POSITIONS['tee'] : POSITIONS[shots[index - 1].position]
    if (this.state.disableAddShot || availablePositions.length === 0) {
      return
    } 
    const newShot = {
      shot_number: index,
      availablePositions: availablePositions,
      position: availablePositions[0].value,
      edit: true,
    }
    if (index === 0) {
      shots.push({
        shot_number: newShot.shot_number,
        position: newShot.position,
        edit: false,
      })
      this.setState({shots: shots, addShot: false, newShot: {}, enableSave: true})
    } else {
      this.setState({addShot: true, newShot: newShot, enableSave: false, disableAddShot: true})
    }
  }

  handleCancelNewShot() {
    const shotsInEditMode = this.shotsInEditMode()
    const enableSaveButton = shotsInEditMode ? false : true
    this.setState({addShot: false, newShot: {}, enableSave: enableSaveButton, disableAddShot: false})
  }

  handelConfirmNewShot() {
    const newShot = { ...this.state.newShot }
    const shots = [ ...this.state.shots ]
    const isPickUp = newShot.position === 'pick-up'
    shots.push({
      shot_number: newShot.shot_number,
      position: newShot.position,
      edit: false,
    })

    if (isPickUp) {
      this.handleCompletedChange(true)
    }

    this.setState({shots: shots, addShot: false, newShot: {}, enableSave: true, disableAddShot: isPickUp})
  }

  onSave() {
    const { holeId, editHole } = this.props
    const { shots, completed} = this.state
    this.setState({enableSave: false})

    editHole(holeId, shots, completed)
  }

  handleClearAllShots() {
    const { playerName, holeNumber, clearAllShots, holeId } = this.props
    // eslint-disable-next-line no-alert
    if (confirm(window.I18n.t('shot_by_shot.view.we_are_going_to_delete', {holeNumber: holeNumber, playerName: playerName})) === true) {
      clearAllShots(holeId)
    }
  }

  shotsInEditMode() {
    return this.state.shots.some(shot => shot.edit === true)
  }

  render() {
    const { playerName, foursomePosition, holeNumber, show, par, scoreVerified, closePopup, isAdmin, isCustomerManager} = this.props
    const { shots, completed, newShot, enableSave } = this.state
    const title = window.I18n.t('shot_by_shot.view.title', {playerName: playerName, foursomePosition: foursomePosition, holeNumber: holeNumber, par: par})
    const shotsInEditMode = this.shotsInEditMode()
    const notAdminAndScoreVerified = !isAdmin && !isCustomerManager && scoreVerified
    const disabledClass = notAdminAndScoreVerified ? 'disabled ' : ''
    const customButton = [
      {
        class: 'pull-left btn button ' + disabledClass,
        text: 'Clear All Shots',
        onClick: this.handleClearAllShots,
      },
    ]
    return (
      <GlgPopup title={ title }
                show={ show }
                onClose={ closePopup }
                onSave={ this.onSave }
                manualClose={true}
                saveButtonEnabled={ enableSave && !notAdminAndScoreVerified }
                customButtons={customButton}>
        <div className='btsp_form_content grey_border shots_summary'>
          <table className='shots_summary_table table table-striped tables_v'>
            <thead>
              <tr>
                <th style={{width: '25%'}}>Shot number</th>
                <th>Position</th>
                <th style={{width: '20%'}}>Actions</th>
              </tr>
            </thead>
            <tbody>
              { shots.map( (shot, index) => {
                const availablePosition = index === 0 ? POSITIONS['tee'] : POSITIONS[shots[index - 1].position]
                const style = ( shotsInEditMode || this.state.addShot ) && !shot.edit ? 'shadow' : ''
                const shotEditDisabledClass = index === 0 || notAdminAndScoreVerified ? 'disabled ' : ''
                return <tr key={shot.shot_id} className={style}>
                  <td className='add-padding-right-10'>{shot.shot_number + 1}</td>
                  <td className='add-padding-right-20 shot_position'>
                    {shot.edit ? 
                      <GlgSelect
                        items={availablePosition}
                        value={shot.position}
                        onChange={(value) => { this.handlePositionChange(index, value) }}/>
                      : shot.position}
                  </td>
                  { !shot.edit &&
                    <td>
                      <a className={shotEditDisabledClass + 'add-padding-right-5'} onClick={() => this.toggleEdit(index)} disabled={index === 0}><i className='gga-pencil-45-angle'/></a>
                      <a className={index === 0 || notAdminAndScoreVerified ? shotEditDisabledClass : 'force_red'} onClick={() => this.handleRemove(index)} disabled={notAdminAndScoreVerified || index === 0}><i className='gga-table-delete-member'/></a>
                    </td>
                  }
                  { shot.edit &&
                    <td>
                      <a className='add-padding-right-5' onClick={() => this.handleEdit(index)}><i className='gga-confirm'/></a>
                      <a className='force_red' onClick={() => this.toggleEdit(index)}><i className='gga-cancel-x'/></a>
                    </td>
                  }
                </tr>
                }
              )}
              { this.state.addShot && 
                <tr>
                  <td className='add-padding-right-10'>{newShot.shot_number + 1}</td>
                  <td className='add-padding-right-20 shot_position'>
                    <GlgSelect
                      items={newShot.availablePositions}
                      value={newShot.position}
                      onChange={(value) => { this.handlePositionChange(shots.length, value) }}/>
                  </td>
                  <td>
                    <a className='add-padding-right-5' onClick={() => this.handelConfirmNewShot()}><i className='gga-confirm'/></a>
                    <a className='force_red' onClick={() => this.handleCancelNewShot()}><i className='gga-cancel-x'/></a>
                  </td>
                </tr>
              }
            </tbody>
          </table>
          <a className={disabledClass + 'pull-right'} onClick={() => this.handleAddShot()}>Add shot</a>
          <GlgCheckbox name='completed'
                      inline={true}
                      label="Mark as completed"
                      checked={ completed } 
                      onChange={(checked) => this.handleCompletedChange(checked)}
                      disabled={notAdminAndScoreVerified}/>
        </div>
      </GlgPopup>
    )
  }
}

HoleSummaryComponent.propTypes = {
  playerName: PropTypes.string,
  foursomePosition: PropTypes.number,
  holeId: PropTypes.string,
  holeNumber: PropTypes.number,
  shots: PropTypes.array,
  show: PropTypes.bool,
  isCompleted: PropTypes.bool,
  closePopup: PropTypes.func,
  editHole: PropTypes.func,
  clearAllShots: PropTypes.func,
  par: PropTypes.number,
  scoreVerified: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isCustomerManager: PropTypes.bool,
}

export default HoleSummaryComponent
