import React from 'react'
import PropTypes from 'prop-types'
import RadioOption from './radio_option'
import GlgTooltipIcon from '../../../../shared/components/glg_tooltip_icon'

export const extractValue = (obj) => {
  const res = {}
  res[obj.key] = obj.selected
  return res
}

function RadioGroup({id, label, tooltip_label, choices, selected, setOption, vertical = false, className = ''}) {
  return (
    <div className={`radio-option ${className}`}>
      {
        label && <b>{label}</b>
      }
      {
        tooltip_label && 
        <GlgTooltipIcon
          tooltip={tooltip_label}
          placement={"right"}
          iconClass={"info_circle"}
        />
      }

      <div className={`options-container ${vertical ? 'vertical' : ''}`}>
        {choices.map((choice, index) => {
          return (
            <RadioOption
              id={id}
              key={`${id}_${index}`}
              {...choice}
              selected={choice.value === selected}
              setOption={setOption}
            />
          )
        }
        )}
      </div>
    </div>)
}

RadioGroup.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  tooltip_label: PropTypes.string,
  choices: PropTypes.array,
  selected: PropTypes.any,
  setOption: PropTypes.func,
  vertical: PropTypes.bool,
  className: PropTypes.string,
}

export default RadioGroup
