import React from 'react'

import { Col } from 'react-flexbox-grid'

const legendComponent = () => {
  const legendPositions = [ 'Tee', 'Fairway', 'Rough', 'Bunker Fairway', 'Bunker Greenside', 
    'Green', 'Penalty', 'Pick-Up' ]
  const legendAbbrevations = [ 'T', 'FW', 'R', 'BF', 'BG', 'G', 'P', 'PU' ]

  return (
    <Col sm={12} md={9} lg={7} className='pull-right hidden-xs legend btsp_top_buttons_container'>
      <table className='table tables_v2 table-striped legend'>
        <thead>
          <tr>
            <th>Shot Type</th>
            { legendPositions.map( pos => <th key={pos}>{pos}</th> ) }
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Abbrevation</td>
            { legendAbbrevations.map( abbr => <td key={abbr}>{abbr}</td> ) }
          </tr>
        </tbody>
      </table>
    </Col>
  )
}

export default legendComponent
